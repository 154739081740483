.error-page {
    background-color: $streambank-blue80;
    color: $streambank-white;
    height: 100%;
    width: 100%;
}

.error-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1.5em;
    padding: 1em 0;

    div {
        flex: 0 1 auto;
        margin: 0.5em;
    }

    .error-text {
        font-size: 18px;
    }

    .error-message {
        text-align: left;
        overflow-y: auto;
        overflow-x: hidden;
        ul {
            li {
                &.main-error {
                    font-weight: 400;
                    font-size: 14px;
                    margin-bottom: 1em;
                    text-align: center;
                    text-decoration: underline;
                    white-space: normal;
                }
                list-style: none;
            }
        }
    }

    .btn-complete {
        margin-right: 10px;
    }
}
