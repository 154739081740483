.photo-points-page {
    .page-title-component {
        .fa-layers {
            margin-right: 8px;

            svg.fa-circle {
                color: $streambank-primary;
            }
        }
    }

    .detail-box {
        background-color: $streambank-grey40;
        border-radius: 5px;
        padding: 10px;

        .col-9 {
            padding-right: 0;
        }

        .detail-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btn-review {
                padding: 8px 24px;
                background-color: $streambank-complete;
                border-color: $streambank-complete;
            }
        }
    }
}

.photo-tool-page, .photo-point-detail-page {
    .photo-point-detail-header {
        padding: 30px 30px 0 30px;
    }

    .photo-point-detail-header, .photo-tool-sidebar {
        .close-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: 0 auto;

            .btn-close {
                font-size: 14px;
                cursor: pointer;

                svg {
                    color: $streambank-danger;
                    margin-right: 0.5rem;
                    font-size: 14px;
                }
            }
        }

    }

    .page-title .read-only-icon {
        cursor: unset;
        pointer-events: none;
    }
;
}

.photo-point-detail-page {
    background-color: $streambank-blue46;
    color: $streambank-white;
    font-size: 12px;
    user-select: none;
    -webkit-touch-callout: none;

    .streambank-page {
        padding-top: 0;

        .streambank-body {
            overflow: inherit;
            overflow-y: inherit;
            height: 100%;
        }
    }


    //.content {
    //    padding: 0;
    //    height: auto;
    //}

    hr {
        background-color: $streambank-grey30;
    }

    svg {
        margin-right: 6px;
    }

    .photo-detail-main {
        height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
        justify-content: center;

        .photo {
            width: 100%;
            min-height: calc(70vh - 20px);
            position: relative;
            margin: 20px auto 0 auto;

            &::after {
                display: block;
                content: '';
                padding-bottom: 56.25%;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 8px;
                border: 3px solid $streambank-white;
                background-color: $streambank-blue46;

                &.current-photo {
                    z-index: 1;
                    opacity: 100;
                    transition: opacity 0.5s;

                    &.hide {
                        opacity: 0;
                        transition: opacity 0.5s;
                    }
                }

                &.previous-photo {
                    position: absolute;
                    z-index: 0;
                }
            }

            .caption {
                z-index: 2;
                border: 3px solid;
                border-top: none;
            }
        }

        @media (max-width: 1024px) {
            .photo::after {
                padding-bottom: 75%;
            }
        }

        .photo-action {
            display: flex;
            justify-content: center;
            font-size: 14px;
            color: $streambank-blue10;
            padding-top: 10px;
        }

        .side-info {
            display: flex;
            flex-direction: column;

            hr {
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
            }

            .form-group {
                margin-bottom: 0.4rem;
            }

            .photo-info {
                padding-bottom: 5px;
                font-size: 10px;

                &.photo-notes {
                    flex: 1;
                }

                .form-group {
                    height: 100%;
                    padding-bottom: 25px;
                }

                .photo-info-data {
                    font-size: 12px;
                }

                textarea {
                    height: 100%;
                    font-size: 12px;
                }
            }

            .btn {
                width: 100%;
                margin-top: 15px;
            }
        }
    }
}

.photo-point-detail-page, .photo-points-page, .transect-photo-review-page, .review-and-finalize-page {
    .photo {

        &.conflict {
            border: 1px solid $streambank-red;
            background-color: $streambank-red;
            border-radius: 8px;
        }
        
        .caption {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 6px 12px;
            background-color: rgba(0, 0, 0, 0.6);
            color: $streambank-white;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            flex-wrap: wrap;

            &.discontinued {
                border-radius: 8px;
                height: 100%;
            }

            .caption-group {
                padding: 0px 4px;
                min-width: 0;
                overflow: hidden;
                text-overflow: ellipsis;

                svg {
                    margin-right: 8px;
                }

                span {
                    font-size: 12px;
                }
            }
        }
        margin: 15px 0px;
    }
}

.photo-point-modal, .deactivate-photo-point-modal, .procedure-modal, .transect-modal {
    .modal-content > .container > .modal-body {
        // allow react-select drop down
        // to overflow out of these particular
        // modals. TODO: remove this if we
        // stop using react-select in the future
        overflow: visible;
    }
}

.photo-point-modal, .deactivate-photo-point-modal {
    
    .error-msg {
        font-size: 12px;
        color: $streambank-danger;
    }

    button {
        padding: 6px 30px;
    }
}
