.new-event-page {
    .checkbox-list {
        max-height: 26.5rem;
    }
}

.sample-event-log-modal {
    .checkbox-list {
        max-height: 14em;
    }
}

.review-and-finalize-page {
    .sample-event-details {
        background-color: $streambank-grey40;
        border-radius: 8px;
        padding: 10px 15px;

        .col-3:not(:first-child), .col-6 {
            border-left: 1px solid $streambank-grey20;
        }

        .form-group {
            label {
                color: $streambank-grey10;
                margin-bottom: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .page-footer {
        .form-group {
            margin-bottom: 0;
        }
        .complete-checkbox {
            .form-check {
                padding-left: 1.5rem;
                padding-right: .5rem;
            }
        }
    }

    .action-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        .btn {
            white-space: nowrap;
        }

        .complete-checkbox {
            label {
                // let's click event propagate to parent div
                // because on this page the event handler is
                // the div instead of the checkbox itself
                pointer-events: none;
            }
        }
    }

    svg.fa-angle-right {
        margin-left: 8px;
    }

    .review-title {
        font-family: "Source Serif Pro", serif;
        font-size: 14px;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }

    .review-photos {
        .photo-point-content {
            .photo {
                &::after {
                    padding-bottom: 100%;
                }

                .caption {
                    flex-direction: column;
                    justify-content: unset;
                    align-items: flex-start;
                }
            }
        }
    }

    .streambank-table {
        th {
            font-size: 10px;
            font-weight: 400;
            line-height: 10px;
            padding: 10px;
            color: $streambank-grey10;
        }

        td {
            &.required::after {
                content: "*";
                color: $streambank-danger;
                padding-left: 5px;
                font-weight: 700;
            }

            .no-data-message {
                color: $streambank-danger;
                font-weight: 700;
            }

            .legacy-data-message {
                color: $streambank-danger;
                font-weight: 700;
            }

            .species-row {
                width: 33%;
            }

            &:first-child {
                vertical-align: top;
            }

            & > .table-responsive {
                margin: -5px;
            }

            &:not(:first-child) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &:not(:last-child) {
                border-right: 1px solid $streambank-grey30;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &.photo-table {
            td {
                vertical-align: top;

                &.photo-point-row {
                    width: 20%;

                    .fa-check-circle {
                        color: $streambank-success;
                    }
                }

                &.transect-name {
                    width: 15%;
                }

            }
        }
        .photo {
            aspect-ratio: 1;
            margin: 0;
            min-width: 5rem;
            position: relative;
            
            &:not(:last-child) {
                margin-bottom: 0.75rem;
            }

            .no-image {
                border: 2px solid $streambank-danger;
            }

            .caption {
                flex-direction: column;
                justify-content: unset;
                align-items: flex-start;
            }
        }
    }

    .sub-table {
        margin-left: -5px;
        > .table-responsive > table {
            margin-bottom: 0;
        }
    }
}
