.transects-page {
    h2 {
        font-size: 14px;
        padding-top: 20px;
    }

    .tile-list {
        margin-right: -12px;

        & > .col-3 {
            padding-left: 8px;
            margin-top: 10px;
        }

        .tile-item {
            cursor: auto;
            margin-right: 6px;

            &.primary, &.success {
                color: $streambank-white;
            }

            .tile-title {
                padding-right: 0;
            }
            .tile-progress {
                 .progress {
                     margin-bottom: 4px;
                 }
            }

            .fa-fw {
                &.disabled {
                    opacity: 0.5;
                }
            }
        }

        .tile-details {
            font-size: 13px;
            margin: 10px 0 0 -12px;
            padding: 15px;

            .transect-details {
                height: 100%;
                width: 100%;
                padding-left: 5px;
                overflow: hidden;

                .col-3 {
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;

                    & > .row-cols-12 {

                        &.hydrozones {
                            & > .col-6 {
                                .form-group {
                                    .form-label {
                                        white-space: nowrap;
                                        overflow: visible;
                                    }

                                    div.hydrozone-label {
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: clip;
                                    }

                                    div.hydrozone-size {
                                        white-space: nowrap;
                                        overflow: visible;
                                    }
                                }
                            }
                        }

                        & > .col-6 {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            &:first-child {
                                padding-right: 0;
                            }

                            &:last-child {
                                padding-left: 10px;
                            }

                            .btn-action {
                                margin-top: 20px;
                                white-space: nowrap;
                            }
                        }
                    }

                    .photo-buttons {
                        display: flex;
                        justify-content: space-evenly;
                        flex-wrap: wrap;

                        > button {
                            margin: 2px 2px;
                            flex-grow: 1;
                            max-width: 5rem;
                        }
                    }

                    &:not(:first-child) {
                        border-left: 1px solid $streambank-grey20;
                    }

                    .transect-notes {
                        white-space: normal;
                        max-height: 6ex;
                    }
                }
            }
        }
    }
}

.transect-detail-page {

    .species-input input {
        background-color: $streambank-white;

        &[disabled] {
            background-color: $streambank-grey40;
        }
    }

    //.notes-field {
    //    display: flex;
    //    flex-direction: column;
    //    height: 90%;
    //
    //    textarea {
    //        flex: 1;
    //        height: 100%;
    //    }
    //}

    .fa-info-circle {
        @extend %small-touchable-area;
        margin-left: 6px;
    }

    .hydrozone-row {
        input[type="number"]:disabled {
            background-color: $streambank-white;
            color: $streambank-grey30;
        }

        .col-4 {
            display: flex;
            align-items: center;
        }

        .end-length-desc {
            font-size: 12px;
            display: flex;
            justify-content: flex-end;
            height: 18px;
            line-height: 18px;
            margin-top: -10px;
        }
    }

    .btn-location {
        @extend %small-touchable-area;
        position: absolute;
        top: 37px;
        right: -25px;

        .fa-circle {
            color: $streambank-primary;
        }
    }

    .btn-activate {
        background-color: #FFF578;
        border-color: #FFF578;
        color: #860AA3;

        &:focus, &:active, &.active, &:hover, &:active:hover {
            background-color: darken(#FFF578, 10);
        }

        .fa-stack {
            margin: -5px 10px -8px 0;

            .fa-certificate {
                color: $streambank-white;
            }
        }
    }
}

.transect-narrative-page {
    .streambank-page {
        padding-left: 0;
        padding-right: 0;
    }

    .streambank-header, .streambank-body, .page-footer {
        padding-left: 15px;
        padding-right: 15px;
    }

    h2 {
        font-size: 14px;
        padding-top: 20px;

        &.first-header {
            padding-top: 0;
        }
    }

    label {
        color: $streambank-grey10;
    }

    .fa-info-circle {
        @extend %small-touchable-area;
        margin-left: 6px;
    }
}

.transect-narrative-review-page {
    .streambank-table {
        td {
            ol {
                list-style: disc;
                padding-left: 1rem;
                margin: 0;
            }
        }
    }
}
